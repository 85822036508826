import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faFacebook,
  faYoutube,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { faPhoneAlt, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

function TopNavigation() {
  return (
    <div className="d-none d-md-block container-fluid top-menu position-fixed bg-oceanblue">
      <div className="d-flex flex-row justify-content-between">
        <div className="address-details d-flex text-white">
          <div className="p-2 d-md-inline-block">
            <FontAwesomeIcon icon={faMapMarkerAlt} />
            &nbsp;<a href="#gmapview">Address: 414 Peddie Road</a>
          </div>
          <div className="p-2 d-md-inline-block">
            <FontAwesomeIcon icon={faPhoneAlt} />
            &nbsp;Phone:&nbsp;<a href="tel:+2710 238 0258">+2710 238 0258</a>
            &nbsp;|&nbsp;<a href="tel:+2711 902 5564">+2711 902 5564</a>
          </div>
          <div className="p-2 d-md-inline-block">
            <FontAwesomeIcon icon={faClock} />
            &nbsp;Office Hours 5 Days a week from 08:00am to 17:00pm
          </div>
        </div>

        <ul className="d-flex social-list d-none d-md-flex align-items-center">
          <li className="fa-icon">
            <FontAwesomeIcon icon={faTwitter} />
          </li>
          <li className="fa-icon">
            <FontAwesomeIcon icon={faFacebook} />
          </li>
          <li className="fa-icon">
            <FontAwesomeIcon icon={faInstagram} />
          </li>
          <li className="fa-icon">
            <FontAwesomeIcon icon={faYoutube} />
          </li>
        </ul>
      </div>
    </div>
  );
}

export default TopNavigation;
