import React, { useState } from "react";
import { SendFormData } from "../backend/ServerFuctions.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faFacebook,
  faYoutube,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

import { faPhoneAlt, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";

function Footer() {
  const [serverMsg, setServerMsg] = useState("");
  const formReset = {
    name: "",
    email: "",
    message: "",
  };
  const [form, setState] = useState(formReset);

  const updateField = (e) => {
    setServerMsg("");
    setState({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (form.name !== "" && form.email !== "" && form.message !== "") {
      SendFormData(form, "../backend/contact-us.php").then((data) => {
        if (data.status === "success") {
          setState(formReset);
        }
        setServerMsg(data.message);
        setTimeout(() => {
          setServerMsg("");
        }, 3500);
      });
    } else {
      setServerMsg("Please fill in all fields marked with *");
      setTimeout(() => {
        setServerMsg("");
      }, 3500);
    }
  };

  return (
    <div className="container-fluid bg-white footer-section pt-4">
      <div className="row">
        <div className="col-12 contact-details">
          <div className="container">
            <div className="row bg-oceanblue">
              <div className="col-12 col-sm-6 py-4 text-white pl-5">
                <h4 className="h4 text-uppercase">Contact Us</h4>
                <div className="py-2">
                  <FontAwesomeIcon icon={faMapMarkerAlt} />
                  &nbsp;&nbsp;414 Peddie Road, Wadeville, South Africa, 1422
                </div>
                <div className="py-2">
                  <FontAwesomeIcon icon={faPhoneAlt} />
                  &nbsp;&nbsp;
                  <a className="text-white" href="tel:+2710 238 0258">
                    +2710 238 0258
                  </a>
                  &nbsp;|&nbsp;
                  <a className="text-white" href="tel:+2711 902 5564">
                    +2711 902 5564
                  </a>
                </div>
                <div className="py-2">
                  <FontAwesomeIcon icon={faEnvelope} />
                  &nbsp;&nbsp;
                  <a
                    className="text-white"
                    href="mailto:info@mendigroup.africa"
                  >
                    info@mendigroup.africa
                  </a>
                </div>
              </div>
              {serverMsg !== "" && (
                <div className="col-12 col-sm-6 d-flex justify-content-center align-items-center font-weight-bold py-2">
                  <div className="server-message px-5">{serverMsg}</div>
                </div>
              )}
              {serverMsg === "" && (
                <div className="col-12 col-sm-6 py-4">
                  <form
                    className="contact-form bg-white p-4"
                    onSubmit={handleSubmit}
                    method="POST"
                  >
                    <div className="form-group">
                      <label htmlFor="full-name" className="font-weight-bold">
                        Full Name<sup>*</sup>
                      </label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        id="full-name"
                        placeholder="Full name"
                        onChange={updateField}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="email" className="font-weight-bold">
                        Email Address<sup>*</sup>
                      </label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        id="email"
                        placeholder="Email address"
                        onChange={updateField}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="message" className="font-weight-bold">
                        Message<sup>*</sup>
                      </label>
                      <textarea
                        name="message"
                        className="form-control"
                        id="message"
                        rows="3"
                        placeholder="Your message here"
                        onChange={updateField}
                        required
                      ></textarea>
                    </div>
                    <div className="my-5 d-flex justify-content-end">
                      <button type="submit" className="btn btn-submit ">
                        Send Message
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>

        <div id="gmapview" className="col-12 mapview posr-150 px-0">
          <div className="gmap_canvas">
            <iframe
              title="mapview"
              width="100%"
              height="768"
              id="gmap_canvas"
              src="https://maps.google.com/maps?q=414%20Peddie%20Road%2C%20Wadeville%2C%20South%20Africa%2C%201422&t=&z=13&ie=UTF8&iwloc=&output=embed&SameSite=Lax"
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
            ></iframe>
          </div>
        </div>

        <div className="col-12 posr-65">
          <div className="container">
            <div className="row d-flex justify-content-center align-items-center">
              <div className="col-10 coordinates bg-green text-white py-4">
                GPS Coordinates:
                <br />
                -26.270212, 28,171340
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 pt-100 posr-80 bg-oceanblue">
          <div className="container px-0">
            <div className="row text-white footer-fs no-gutters">
              <div className="ccol-12 col-lg-5 col-sm-6  py-4">
                <h4 className="text-white font-weight-bold text-uppercase">
                  Mendigroup
                </h4>
                <div className="row no-gutters">
                  <div className="col-8">
                    <p>
                      The <em>Mendi Group</em> of companies are a group of
                      companies which focus in the transport and energy sectors.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-3 col-sm-6 py-4">
                <ul className="d-flex flex-column justify-content-center">
                  <li className="py-2">
                    <Link className="pr-4 active text-white" to="/about">
                      About
                    </Link>
                  </li>
                  <li className="py-2">
                    <Link className="pr-4 text-white" to="/companies">
                      Companies
                    </Link>
                  </li>
                  <li className="py-2">
                    <Link className="pr-4 text-white" to="/services">
                      Services
                    </Link>
                  </li>
                  <li className="py-2">
                    <Link className="pr-4 text-white" to="/news">
                      News
                    </Link>
                  </li>
                  <li className="py-2">
                    <Link className="pr-4 text-white" to="/careers">
                      Careers
                    </Link>
                  </li>
                  <li className="py-2">
                    <Link className="pr-0 text-white" to="/contact">
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-12 col-lg-4 col-sm-6 py-4">
                <div className=" d-flex flex-column justify-content-end footer-address">
                  <div className="py-2">
                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                    &nbsp;&nbsp;
                    <a className="text-white" href="#gmapview">
                      414 Peddie Road, Wadeville, South Africa, 1422
                    </a>
                  </div>
                  <div className="py-2">
                    <FontAwesomeIcon icon={faPhoneAlt} />
                    &nbsp;&nbsp;
                    <a className="text-white" href="tel:+2710 238 0258">
                      +2710 238 0258
                    </a>
                    &nbsp;|&nbsp;
                    <a className="text-white" href="tel:+2711 902 5564">
                      +2711 902 5564
                    </a>
                  </div>
                  <div className="py-2">
                    <FontAwesomeIcon icon={faEnvelope} />
                    &nbsp;&nbsp;
                    <a
                      className="text-white"
                      href="mailto:info@mendigroup.africa"
                    >
                      info@mendigroup.africa
                    </a>
                  </div>
                  <div className="pt-5 pb-2">
                    <h4 className="text-white font-weight-bold">
                      Our Social Media
                    </h4>
                    <ul className="d-flex social-list">
                      <li className="fa-icon">
                        <FontAwesomeIcon icon={faTwitter} />
                      </li>
                      <li className="fa-icon">
                        <FontAwesomeIcon icon={faFacebook} />
                      </li>
                      <li className="fa-icon">
                        <FontAwesomeIcon icon={faInstagram} />
                      </li>
                      <li className="fa-icon">
                        <FontAwesomeIcon icon={faYoutube} />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-12 pb-4">
                <div className="d-flex justify-content-between flex-wrap">
                  <div className="copyright">
                    Copyright © Mendi Group {new Date().getFullYear()}. All
                    rights reserved.
                  </div>
                  <div className="privacy">
                    <a className="text-white" href="/">
                      Terms and Conditions
                    </a>
                    &nbsp;|&nbsp;
                    <a className="text-white" href="/">
                      Privacy Policy
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
