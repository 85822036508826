import { mapDirections, dropdownArrow } from "../components/Images.js";
import { content } from "../shared/flat-db.js";

function Contact() {
  const uuid = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };

  return (
    <div className="container-fluid bg-white pt-4">
      <div className="row justify-content-start">
        <div className="col-11 p-0 posr-255">
          <h2 className="text-heading m-0 pl-5 text-uppercase text-mendiblue font-weight-bold">
            {content.contact.title}
          </h2>

          <div className="bg-mendiblue p-4 pb-5 text-white ">
            <div className="d-flex justify-content-end">
              <div className="scroll-arrow">
                <img src={dropdownArrow} width="32px" alt="drop-arrow" />
              </div>
            </div>
            <div className="d-flex flex-wrap flex-lg-nowrap align-items-center justify-content-center">
              <div className="para-high lh-1 p-5">
                {content.contact.data.banner.heading}
              </div>
              <p className="p-5">{content.contact.data.banner.paragraph}</p>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row pt-5">
            {content.contact.data.main.items.map((item, index) => {
              if (index % 2 === 0) {
                return (
                  <>
                    <div key={uuid()} className={`col-12 col-sm-4`}>
                      <h4 className="h4 m-0 text-uppercase text-mendiblue font-weight-bold">
                        {item.name}
                      </h4>
                      <div
                        className="footer-fs"
                        dangerouslySetInnerHTML={{ __html: item.paragraph }}
                      />
                    </div>
                    <div key={uuid()} className={`col-12 col-sm-8 pb-5`}>
                      <img
                        src={mapDirections}
                        alt="directions"
                        className="img img-fluid"
                        width="100%"
                        height="250px"
                      />
                    </div>
                  </>
                );
              } else {
                return (
                  <>
                    <div key={uuid()} className={`col-12 col-sm-8 pb-5`}>
                      <img
                        src={mapDirections}
                        alt="directions"
                        className="img img-fluid"
                        width="100%"
                        height="250px"
                      />
                    </div>
                    <div key={uuid()} className={`col-12 col-sm-4`}>
                      <h4 className="h4 m-0 text-uppercase text-mendiblue font-weight-bold">
                        {item.name}
                      </h4>
                      <div
                        className="footer-fs"
                        dangerouslySetInnerHTML={{ __html: item.paragraph }}
                      />
                    </div>
                  </>
                );
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
