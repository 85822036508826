import { logo, dropdownArrow } from "../components/Images.js";
import { content } from "../shared/flat-db.js";

function Companies() {
  return (
    <div className="container-fluid bg-white pt-4">
      <div className="row justify-content-start">
        <div className="col-11 p-0 posr-255">
          <h2 className="text-heading m-0 pl-5 text-uppercase text-orange font-weight-bold">
            {content.companies.title}
          </h2>
          <div className="bg-orange p-4 pb-5 text-white ">
            <div className="d-flex justify-content-end">
              <div className="scroll-arrow">
                <img src={dropdownArrow} width="32px" alt="drop-arrow" />
              </div>
            </div>
            <div className="d-flex flex-wrap flex-lg-nowrap align-items-center justify-content-center">
              <div className="para-high lh-1 p-5">
                {content.companies.data.banner.heading}
              </div>
              <p className="p-5">{content.companies.data.banner.paragraph}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 py-5">
          <div className="container">
            <div className="row">
              <div className="col-12 py-4">
                <h4 className="h4 tt-upperscase text-oceanblue">
                  {content.companies.data.main.heading}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      {content.companies.data.main.items.map((item, index) => {
        if (index % 2 === 0) {
          return (
            <div
              key={index}
              className="row pb-4 px-0 justify-content-end align-items-center"
            >
              <div className="col-6 lft">
                <div
                  className={`d-none d-md-block companies-img ${item.img}`}
                ></div>
              </div>
              <div
                className={`col-6 bg-${item.bg} companies-card text-white p-5`}
              >
                <div className="pl-5">
                  <div className="pb-5">
                    <img
                      src={logo}
                      alt="mendi-group-logo"
                      style={{ width: "100px" }}
                    />
                  </div>

                  <div className="font-weight-bold text-uppercase">
                    {item.heading}
                  </div>

                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.paragraph,
                    }}
                  />
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <div
              key={index}
              className="row pb-4 px-0 justify-content-start align-items-center"
            >
              <div
                className={`col-6 bg-${item.bg} companies-card text-white p-5`}
              >
                <div className="pr-5">
                  <div className="pb-5">
                    <img
                      src={logo}
                      alt="mendi-group-logo"
                      style={{ width: "100px" }}
                    />
                  </div>

                  <div className="font-weight-bold text-uppercase">
                    {item.heading}
                  </div>

                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.paragraph,
                    }}
                  />
                </div>
              </div>
              <div className="col-6 rgt">
                <div
                  className={`d-none d-md-block companies-img ${item.img}`}
                ></div>
              </div>
            </div>
          );
        }
      })}
    </div>
  );
}

export default Companies;
