import { logo, dropdownArrow } from "../components/Images.js";
import { content } from "../shared/flat-db.js";

function About() {
  return (
    <div className="container-fluid bg-white pt-4">
      <div className="row justify-content-start">
        <div className="col-11  p-0 posr-255">
          <h2 className="text-heading m-0 pl-5 text-uppercase text-skyblue font-weight-bold">
            {content.about.title}
          </h2>
          <div className="bg-skyblue p-4 pb-5 text-white ">
            <div className="d-flex justify-content-end">
              <div className="scroll-arrow">
                <img src={dropdownArrow} width="32px" alt="drop-arrow" />
              </div>
            </div>
            <div className="d-flex flex-wrap flex-lg-nowrap align-items-center justify-content-center">
              <div className="p-5">
                <img
                  src={logo}
                  alt="mendi-group-logo"
                  style={{ width: "300px" }}
                />
              </div>
              <div
                className="p-2 p-lg-5"
                dangerouslySetInnerHTML={{ __html: content.about.content }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 py-5">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-6 pt-4">
                <h4 className="h4 tt-upperscase text-oceanblue pb-4">
                  {content.about.valueProposition.title}
                </h4>
                <h2
                  className="pr-5 font-weight-bold text-mendiblue"
                  dangerouslySetInnerHTML={{
                    __html: content.about.valueProposition.subTitle,
                  }}
                />
              </div>
              <div
                className="col-12 col-lg-6 text-oceanblue pt-5"
                dangerouslySetInnerHTML={{
                  __html: content.about.valueProposition.content,
                }}
              />
            </div>

            <div className="row">
              <div className="col-12 d-flex flex-row flex-wrap justify-content-around py-5 text-mendiblue">
                {content.about.services.map((item, key) => {
                  return (
                    <div className="icons text-center" key={key}>
                      <img src={item.img} width="64px" alt={item.title} />
                      <br />
                      <h3 className="pt-3 text-oceanblue font-weight-bold">
                        {item.title}
                      </h3>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-lg-6 pt-4">
                <h4 className="h4 tt-upperscase text-oceanblue">
                  {content.about.management.title}
                </h4>
                <p className="pt-3 text-oceanblue">
                  {content.about.management.content}
                </p>
              </div>

              <div className="col-12 d-flex flex-row flex-wrap justify-content-start pt-3">
                {content.about.management.executives.map((exec, key) => {
                  return (
                    <div
                      key={key}
                      className={`exec-profile d-flex flex-row flex-wrap justify-content-between align-items-end exec-${exec.profile} mr-3 mb-3`}
                    >
                      <div className="p-3 text-white">
                        <h5 className="heading font-weight-bold m-0">
                          {exec.name}
                        </h5>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: exec.title,
                          }}
                        />
                      </div>
                      <div className="navi-btn navi-left text-white"></div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
