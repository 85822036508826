import { dropdownArrow } from "../components/Images.js";
import { content } from "../shared/flat-db.js";

function Careers() {
  return (
    <div className="container-fluid bg-white pt-4">
      <div className="row justify-content-start">
        <div className="col-12 col-lg-11 p-0 posr-255">
          <h2 className="text-heading m-0 pl-5 text-uppercase text-green font-weight-bold">
            {content.careers.title}
          </h2>
          <div className="bg-green p-4 pb-5 text-white ">
            <div className="d-flex justify-content-end">
              <div className="scroll-arrow">
                <img src={dropdownArrow} width="32px" alt="drop-arrow" />
              </div>
            </div>
            <div className="d-flex flex-wrap flex-lg-nowrap align-items-center justify-content-center">
              <div className="para-high lh-1 p-5">
                {content.careers.content.banner.heading}
              </div>
              <p className="p-5">{content.careers.content.banner.paragraph}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Careers;
