import { ssMendiShip } from "../components/Images.js";
import { content } from "../shared/flat-db.js";

function GroupCompanies() {
  return (
    <div className="row pt-4">
      <div className="col-12 px-0">
        <div className="container text-white">
          <div className="row pb-5">
            <div className="col-12 col-lg-6 py-2 d-flex justify-content-start">
              <div className="p-1">
                <h4 className="h4 text-uppercase">
                  {content.home.data.companies.title}
                </h4>
              </div>
            </div>
          </div>
          <div className="row py-2">
            {content.home.data.companies.items.map((item, index) => (
              <div key={index} className="col-12 col-sm-6 col-lg-3 col-content">
                <h1 className="numbered">{item.heading}</h1>
                <div className="heading py-3">{item.subheading}</div>
                <p className="py-2">{item.paragraph}</p>
                <div className="d-flex justify-content-end pb-3">
                  <a href="/companies">
                    <div className="navi-btn navi-left navi-card d-none"></div>
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="container-fluid bg-white">
          <div className="row py-3">
            <div className="container">
              <div className="row pt-5 brother-content posr-150 text-white">
                <div className="col-12 d-flex flex-column justify-content-center align-items-center">
                  <div className="">
                    <h1 className="heading-big text-uppercase text-center">
                      <div className="w-100">
                        {content.home.data.companies.moto.title}
                      </div>
                    </h1>
                    <div className="py-3 text-center d-flex justify-content-center">
                      <div className="w-50">
                        {content.home.data.companies.moto.paragraph}
                      </div>
                    </div>
                    <div className="py-3 text-center">
                      <strong>
                        - {content.home.data.companies.moto.author}
                      </strong>
                    </div>
                  </div>
                </div>
                <div className="col-12 d-flex justify-content-center pb-5">
                  <div className="ssmendiship">
                    <img src={ssMendiShip} width="300px" alt="ss-mendi-ship" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GroupCompanies;
