import "./App.css";
import TopNavigation from "./components/TopNavigation";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home.js";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Companies from "./pages/Companies.js";
import About from "./pages/About.js";
import Services from "./pages/Services";
import News from "./pages/News";
import Careers from "./pages/Careers";
import Contact from "./pages/Contact";

function App() {
  return (
    <>
      <TopNavigation />
      <Router>
        <Header />
        <Switch>
          <Route path="/services">
            <Services />
          </Route>
          <Route path="/news">
            <News />
          </Route>
          <Route path="/careers">
            <Careers />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/companies">
            <Companies />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default App;
