import { logo } from "./Images.js";
import { Link, useLocation } from "react-router-dom";
import { content } from "../shared/flat-db.js";

function Header() {
  const location = useLocation();
  let section = "";

  switch (location.pathname) {
    case "/about":
      section = "about";
      break;
    case "/careers":
      section = "careers";
      break;
    case "/news":
      section = "news";
      break;
    case "/services":
      section = "services";
      break;
    case "/companies":
      section = "companies";
      break;
    case "/contact":
      section = "contact";
      break;
    default:
      section = "";
      break;
  }

  return (
    <div
      className={`container-fluid header-section ${section} pt-3 pt-md-5 px-3`}
    >
      <div className="row">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-4 d-flex align-items-center justify-content-center justify-content-md-start">
              <a className="py-2" href="/" aria-label="Mendi Group Logo">
                <img src={logo} alt="mendi-group-logo" className="site-logo" />
              </a>
            </div>
            <div className="col-12 col-md-8 main-menu d-flex flex-wrap align-items-center justify-content-start ">
              <Link
                className={`pr-4 ${
                  location.pathname === "/about" ? "active" : ""
                }`}
                to="/about"
              >
                About
              </Link>
              <Link
                className={`pr-4 ${
                  location.pathname === "/companies" ? "active" : ""
                }`}
                to="/companies"
              >
                Companies
              </Link>
              <Link
                className={`pr-4 ${
                  location.pathname === "/services" ? "active" : ""
                }`}
                to="/services"
              >
                Services
              </Link>
              <Link
                className={`pr-4 ${
                  location.pathname === "/news" ? "active" : ""
                }`}
                to="/news"
              >
                News
              </Link>
              {/*<Link
                className={`pr-4 ${
                  location.pathname === "/careers" ? "active" : ""
                }`}
                to="/careers"
              >
                Careers
              </Link>*/}
              <Link
                className={`pr-4 ${
                  location.pathname === "/contact" ? "active" : ""
                }`}
                to="/contact"
              >
                Contact
              </Link>
            </div>
          </div>
        </div>
      </div>
      {location.pathname === "/" && (
        <div className="row">
          <div className="container">
            <div className="row justify-content-end center-vh">
              <div className="col-12 col-sm-10 col-lg-8 py-4 header-content">
                <h1
                  className="h1"
                  dangerouslySetInnerHTML={{
                    __html: content.home.data.banner.heading,
                  }}
                />
                <p
                  className="text-dark"
                  dangerouslySetInnerHTML={{
                    __html: content.home.data.banner.paragraph,
                  }}
                />
                <a href="/about" className="btn btn-learn-more">
                  Learn More
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Header;
