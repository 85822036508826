import { services, ourStory } from "../components/Images.js";

export const content = {
  about: {
    title: "About Us",
    content:
      "<p>The Mendi Group of companies are a group of companies which focus in the transport and energy sectors.</p> <p>The group was founded on the appreciation of the extractive nature of the African economy, with minerals being mined in Africa, transported by road and rail to the nearest port, stockpiled and shipped to customers at the port of destination. </p> <p>Whilst historically, each of these functions have been performed by independent role players in each sector, Mendi has an appreciation for the holistic and consolidated approach that future supply chains are geared towards.</p><p>Accordingly, Mendi companies have been structured to provide each of these services holistically and in an integrated manner under the Mendi banner.</p>",
    valueProposition: {
      title: "Value Proposition",
      subTitle: "The “port of call” from<br/>pit-to-port.",
      content:
        "<p class='pt-5'> Mendi specializes in specialised transport and energy solutions in line with the above-described philosophy. </p> <p> Mendi Group was founded on the back of the appreciation for the extractive nature of the African economy and the supply chain required in order to support such an economy. </p> <p> Mendi’ value proposition is that it becomes a single “port of call” for a client wanting to export bulk commodities, or import liquid bulk commodities. Mendi is able to provide shipping and vessel chartering operations, clearing and forwarding, rail logistics, as well as road logistics required to deliver the commodities from pit-to-port. </p> <p> In addition, Mendi has leveraged on its internal resources and know-how in shipping and logistics to create its own fuel supply chain </p>",
    },
    services: [
      {
        img: services.ship,
        title: "Maritime",
      },
      {
        img: services.rail,
        title: "Rail",
      },
      {
        img: services.truck,
        title: "Logistics",
      },
      {
        img: services.plane,
        title: "Aviation",
      },
      {
        img: services.fuel,
        title: "Fuel",
      },
    ],
    ourStory: [
      {
        img: ourStory.ship,
        title: "Maritime",
      },
      {
        img: ourStory.rail,
        title: "Rail",
      },
      {
        img: ourStory.truck,
        title: "Logistics",
      },
      {
        img: ourStory.plane,
        title: "Aviation",
      },
      {
        img: ourStory.fuel,
        title: "Fuel",
      },
    ],
    management: {
      title: "Mendi Group Executives",
      content:
        "Mendi’s Management is tailor-made and geared towards ensuring that the group achieves its mission and goals.",
      executives: [
        {
          name: "Napo Ramodibedi",
          profile: "chair",
          title: "Executive Chairman",
          url: "https://#",
        },
        {
          name: "Douglas Chauke",
          profile: "ceo",
          title: "Mendi Rail CEO",
          url: "https://#",
        },
       /* {
          name: "Peter Dikhuba",
          profile: "sales",
          title: "Sales Director",
          url: "https://#",
        },*/
        {
          name: "Rethabile Ramodibedi",
          profile: "marketing",
          title: "Marketing Director",
          url: "https://#",
        },
        /*{
          name: "Justice Tembo",
          profile: "projects",
          title: "Head of Projects & <br/>Investments",
          url: "https://#",
        },*/
      ],
    },
  },
  careers: {
    title: "Personnel",
    content: {
      banner: {
        heading:
          "Holistic approach from Rail, Road Logistic, Shipping, Aviation, to Fuel and Energy.",
        paragraph:
          "We work within the SADC region with the aim of working throughout the continent providing quality services for Africa and beyond.",
      },
    },
  },
  companies: {
    title: "Companies",
    data: {
      banner: {
        heading:
          "Holistic approach from Rail, Road Logistic, Shipping, Aviation, to Fuel and Energy.",
        paragraph:
          "We work within the SADC region with the aim of working throughout the continent providing quality services for Africa and beyond.",
      },
      main: {
        heading: "Mendi Group Companies",
        items: [
          {
            bg: "oceanblue",
            img: "mendi-rail-company",
            heading: "Mendi Rail & Engineering",
            paragraph:
              " <p> Mendi Rail and Engineering (Pty)Ltd (“Mendi Rail”) is a rail infrastructure company. Mendi Rail is one of only four companies that manufacter pre-stressed concrete sleepers for Transnet and PRASA in South Africa. Of the four companies, Mendi Rail is the only 100% Black-owned and Black-female owned company </p> <p> Mendi Rail is also only one of two, out of the four companies, where the intellectual property relating to the sleepers is 100% South African owned, with no royalty or other fees being paid to a foreign entity. </p>",
          },
          {
            bg: "red",
            img: "mendi-trident",
            heading: "Mendi Trident",
            paragraph:
              "<p>Mendi Trident is a rolling stock component manufacturer. Mendi Trident manufactures various rolling stock components including armatures, traction motors, blowers, exhausters, compressors, wheels (R6, cannonbox etc.) and many other components. </p><p>Mendi Trident has become one of the leading rolling stock component manufacturer in South Africa. Mendi Trident has distinguished itself through its high quality products, manufactured in line with its ISO 9001 accreditation; as well as its short lead times, due to a combination lean and agile supply chain.</p>",
          },
          {
            bg: "orange",
            img: "mendi-invest",
            heading: "Trading and Investments",
            paragraph:
              "<p>Mendi Trading and Investments (Pty) Ltd is a registered wholesaler of petroleum products including, but not limited to, petrol, diesel, illuminating paraffin, jet fuel and LPG.</p><p>Mendi is supplied its fuel by Sasol. Mendi has an aggressive pricing structure with Sasol due to its longstanding relationship and high volumes. Sasol has also partnered with Mendi as a fuel supply partner outside of South Africa.</p>",
          },
          {
            bg: "green",
            img: "go-energy",
            heading: "Go Energy",
            paragraph:
              "<p>Mendi has identified the business potential contained within the SADC region. Most SADC countries are within a 2hour flight from OR Tambo. At least 4 of them are within 400kms of Johannesburg (Lesotho, Botswana, Swaziland, Mozambique). Most of these countries are not very industrialised and rely on imports from South Africa for their industrial requirements.</p><p>Accordingly, Mendi has established offifices in Botswana, Lesotho and Namibia. Plans are underway to set up a Swaziland and Zambian office soon.</p>",
          },
        ],
      },
    },
  },
  contact: {
    title: "Contact",
    data: {
      banner: {
        heading: "Regional Integration",
        paragraph:
          "A SADC Group headquartered in South Africa with regional offices in Lesotho, Botswana, Namibia and Swaziland.",
      },
      main: {
        items: [
          {
            name: "South Africa",
            paragraph:
              '<div class="py-1">414 Peddie Road</div><div class="py-1">Wadeville</div><div class="py-1">South Africa</div><div class="py-1">1422</div><div class="pt-3">+2711 234 7885</div><div class="pb-1">+2711 902 5564</div><div class="pt-3"><a href="mailto:info@mendigroup.africa">info@mendigroup.africa</a></div>',
            mapUrl: "",
          },
          {
            name: "Lesotho",
            paragraph:
              '<div class="py-1">414 Peddie Road</div><div class="py-1">Wadeville</div><div class="py-1">South Africa</div><div class="py-1">1422</div><div class="pt-3">+2711 234 7885</div><div class="pb-1">+2711 902 5564</div><div class="pt-3"><a href="mailto:info@mendigroup.africa">info@mendigroup.africa</a></div>',
            mapUrl: "",
          },
          {
            name: "Botswana",
            paragraph:
              '<div class="py-1">414 Peddie Road</div><div class="py-1">Wadeville</div><div class="py-1">South Africa</div><div class="py-1">1422</div><div class="pt-3">+2711 234 7885</div><div class="pb-1">+2711 902 5564</div><div class="pt-3"><a href="mailto:info@mendigroup.africa">info@mendigroup.africa</a></div>',
            mapUrl: "",
          },
          {
            name: "Namibia",
            paragraph:
              '<div class="py-1">414 Peddie Road</div><div class="py-1">Wadeville</div><div class="py-1">South Africa</div><div class="py-1">1422</div><div class="pt-3">+2711 234 7885</div><div class="pb-1">+2711 902 5564</div><div class="pt-3"><a href="mailto:info@mendigroup.africa">info@mendigroup.africa</a></div>',
            mapUrl: "",
          },
        ],
      },
    },
  },
  home: {
    title: "Home",
    data: {
      banner: {
        heading:
          "<em>Welcome to</em> an African story based on <em>unity.</em>",
        paragraph:
          "The <em>Mendi Group</em> of companies are a group of companies which focus in the transport and energy sectors.",
      },
      about: {
        title: "About Us",
        heading:
          "Founded on the appreciation for the extractive nature of the African economy.",
        paragraph:
          "The group was founded on the appreciation for the extractive nature of the African economy, with minerals being mined in Africa, transported by road and rail to the nearest port, stockpilled and shipped to the customers at the port of the destination.",
      },
      companies: {
        title: "Group Companies",
        items: [
          {
            heading: "01",
            subheading: "Mendi Rail & Engineering",
            paragraph:
              'Rail and Engineering PTY (LTD) ("Mendi Rail") is a rail infrastructure company. Mendi Rail is one of the four companines that manufacture pre-stressed concretes sleepers for Transnet and PRASA in South Africa.',
          },
          {
            heading: "02",
            subheading: "Mendi Trident",
            paragraph:
              "Mendi Trident is a rolling stock component manufacturer. Mendi Trident manufactures various rolling stock components including armatures, tractor motors, blowers, exhausters, compressors, wheels (R6, cannonbox etc.) and many other components.",
          },
          {
            heading: "03",
            subheading: "Mendi Trading and Investments",
            paragraph:
              "Mendi Trading and Investments (Pty) Ltd is a registered wholesaler of petroleum products including, but not limited to, petrol, diesel, illuminating paraffin, jet fuel and LPG.",
          },
          {
            heading: "04",
            subheading: "Mendi International",
            paragraph:
              "Mendi has identified business potentials contained within the SADC region. Most SADC countries are within 2 hours flight from OR Tambo. At least 4 of them withn 400kms of Johannesburg (Botswana, Lesotho, Swaziland, Mozambique).",
          },
        ],
        moto: {
          title: "Let us strive like brothers.",
          paragraph:
            'Mendi Group will always remember the spirit of SS Mendi "I, a Xhosa, say you are my brothers...Swazi, Pondos, Basotho... so let us die like brothers. We are the sons of Africa"',
          author: "Rev. Issac Wauchope Dyobha",
        },
      },
      careers: {
        title: "Personnel",
        heading: "Transport and energy specialists and captains of industry",
        numbers: [
          {
            number: 12,
            name: "Executives",
          },
          {
            number: 375,
            name: "Employees",
          },
          {
            number: 16,
            name: "Partners",
          },
        ],
      },
    },
  },
  news: {
    title: "News + Views",
    data: {
      banner: {
        heading: "All the latest news & views",
        paragraph:
          "Industry related news affecting all our companies in one place.",
      },
      main: {
        heading: "Mendi News + Views",
        subheading: "February",
        items: {
          topics: [
            {
              name: "All",
              filter: "all",
            },
            {
              name: "Rail & Engineering",
              filter: "rail",
            },
            {
              name: "Shipping",
              filter: "shipping",
            },
            {
              name: "Aviation",
              filter: "aviation",
            },
            {
              name: "Energy",
              filter: "energy",
            },
          ],
          articles: [
            {
              filter: "shipping",
              height: "h-250",
              heading: "Shipping",
              subheading: "Two Decades of Transforming Maritime Assets",
              paragraph: "Dummy text of the printing and typesetting industry.",
            },
            {
              filter: "rail",
              height: "h-600",
              heading: "Rail and Engineering",
              subheading: "Rail Offers Potential For Africa",
              paragraph: "Dummy text of the printing and typesetting industry.",
            },
            {
              filter: "aviation",
              height: "h-450",
              heading: "Aviation",
              subheading:
                "Coronavirus impact on African aviation worse than initially estimated , says IATA",
              paragraph: "Dummy text of the printing and typesetting industry.",
            },
            {
              filter: "energy",
              height: "h-250",
              heading: "Energy",
              subheading:
                "A massive windfarm in South Africa is ready for business - creating energy for nearly 500,000 homes.",
              paragraph: "Dummy text of the printing and typesetting industry.",
            },
          ],
        },
      },
    },
  },
  services: {
    title: "Services",
    data: {
      banner: {
        heading:
          "Holistic approach from Rail, Road Logistic, Shipping, Aviation, to Fuel and Energy.",
        paragraph:
          "We work within the SADC region with the aim of working throughout the continent providing quality services for Africa and beyond.",
      },
      main: {
        heading: "Services",
        subheading: "What we do as Mendi Group",
        paragraph:
          "Mendi specializes in specialised transport and energy solutions in line with the above-described philosophy.",
        items: ["Maritime", "Rail", "Logistics", "Aviation", "Fuel"],
      },
    },
  },
};
