import { dropdownArrow, ourStory } from "../components/Images.js";
import { content } from "../shared/flat-db.js";

function OurStory() {
  return (
    <div className="col-11 p-3 p-md-5 text-left bg-green">
      <div className="row">
        <div
          className="d-flex justify-content-between flex-wrap w-100 align-items-center"
          style={{ padding: "0 5rem" }}
        >
          <h4 className="h4 text-white">Our Story</h4>

          <div className="scroll-arrow">
            <img src={dropdownArrow} width="32px" alt="drop-arrow" />
          </div>
        </div>
        <div className="col-12 py-3 d-flex flex-row flex-wrap justify-content-center align-items-center">
          <img
            src={ourStory.ptp.excavator}
            width="120px"
            alt="excarvator"
            className="px-3"
          />
          <img
            src={ourStory.ptp.cta}
            width="64px"
            height="64px"
            alt="arrow-left"
          />
          <img
            src={ourStory.ptp.truck}
            width="120px"
            alt="truck"
            className="px-3"
          />
          <img
            src={ourStory.ptp.cta}
            width="64px"
            height="64px"
            alt="arrow-left"
          />
          <img
            src={ourStory.ptp.train}
            width="120px"
            alt="train"
            className="px-3"
          />
          <img
            src={ourStory.ptp.cta}
            width="64px"
            height="64px"
            alt="arrow-left"
          />
          <img
            src={ourStory.ptp.ship}
            width="120px"
            alt="ship"
            className="px-3"
          />
        </div>

        <div className="col-12 text-center py-2">
          <h5 className="h5 pt-4 text-white">
            Integrated Pit-to-Port Solution
          </h5>
        </div>

        <div className="col-12 text-center py-2">
          <h2 className="pr-5 font-weight-bold text-white">
            We focus on the following areas:
          </h2>
        </div>
        <div className="col-12 d-flex flex-row flex-wrap justify-content-around py-5 text-mendiblue">
          {content.about.ourStory.map((item, key) => {
            return (
              <div className="icons text-center" key={key}>
                <img src={item.img} width="64px" alt={item.title} />
                <br />
                <h3 className="pt-3 text-white font-weight-bold">
                  {item.title}
                </h3>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default OurStory;
