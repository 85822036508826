import React, { useState } from "react";
import { content } from "../shared/flat-db.js";

function News() {
  const [filter, setFilter] = useState("all");

  return (
    <div className="row py-4 bg-white news-section">
      <div className="container">
        <div className="row">
          <div className="col-12 px-0">
            <h4 className="h4 tt-upperscase text-oceanblue">
              {content.news.data.main.heading}
            </h4>
            <div className="d-flex flex-wrap align-items-center">
              <h2 className="font-weight-bold text-mendiblue">
                {content.news.data.main.subheading}
              </h2>
              <div className="tabbed-nav">
                <ul className="d-flex flex-wrap justify-content-between align-items-center pl-lg-5 p-0">
                  {content.news.data.main.items.topics.map((item, index) => {
                    return (
                      <li
                        key={index}
                        className={filter === item.filter ? "active" : ""}
                        onClick={() => setFilter(`${item.filter}`)}
                      >
                        {item.name}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-12 py-3 px-0">
            <div className="card-columns text-white">
              {content.news.data.main.items.articles.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={
                      filter === "all" || filter === `${item.filter}`
                        ? `card ${item.height} ${item.filter} tab-column`
                        : "d-none"
                    }
                  >
                    <div className="card-body fh p-2">
                      <h5 className="card-title">{item.heading}</h5>
                      <h3 className="card-title">{item.subheading}</h3>
                      <div className="card-text d-none">
                        {item.paragraph}
                        <div className="d-flex justify-content-end">
                          <div className="navi-btn navi-left navi-card"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default News;
