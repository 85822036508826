import { content } from "../shared/flat-db.js";

function Aboutus() {
  return (
    <div className="row py-4">
      <div className="col-12 col-lg-6 about-ship"></div>
      <div className="col-12 col-lg-6 text-white py-2 px-5">
        <h4 className="h4 text-uppercase">{content.home.data.about.title}</h4>
        <div className="para-high lh-1 pt-3">
          {content.home.data.about.heading}
        </div>
        <p className="py-4">{content.home.data.about.paragraph}</p>
        <a href="/about">
          <div className="navi-btn navi-left"></div>
        </a>
      </div>
    </div>
  );
}

export default Aboutus;
