import { clients } from "./Images";

function Clients() {
  return (
    <div className="col-12 col-lg-10 p-2 text-left my-4">
      <h4 className="h4 text-white">Clients</h4>
      <div className="d-flex flex-row flex-wrap pb-4">
        <div className="p-1">
          <img
            src={clients.total}
            alt="client-1"
            className="img-fluid client-logo"
          />
        </div>
        <div className="p-1">
          <img
            src={clients.eskom}
            alt="client-2"
            className="img-fluid client-logo"
          />
        </div>
        <div className="p-1">
          <img
            src={clients.puma}
            alt="client-3"
            className="img-fluid client-logo"
          />
        </div>
        <div className="p-1">
          <img
            src={clients.fraseralexander}
            alt="client-4"
            className="img-fluid client-logo"
          />
        </div>
        <div className="p-1">
          <img
            src={clients.consol}
            alt="client-5"
            className="img-fluid client-logo"
          />
        </div>
        <div className="p-1">
          <img
            src={clients.prasa}
            alt="client-6"
            className="img-fluid client-logo"
          />
        </div>
        <div className="p-1">
          <img
            src={clients.transnet}
            alt="client-7"
            className="img-fluid client-logo"
          />
        </div>
        <div className="p-1">
          <img
            src={clients.trident}
            alt="client-8"
            className="img-fluid client-logo"
          />
        </div>
        <div className="p-1">
          <img
            src={clients.transnetfrieght}
            alt="client-9"
            className="img-fluid client-logo"
          />
        </div>
      </div>
    </div>
  );
}

export default Clients;
