import Aboutus from "../components/Aboutus.js";
import Careers from "../components/Careers.js";
import Clients from "../components/Clients.js";
import GroupCompanies from "../components/GroupCompanies.js";
import News from "../components/News.js";
import OurStory from "../components/OurStory.js";

function Home() {
  return (
    <div className="container-fluid main-section posr-150">
      <div className="row justify-content-end">
        <OurStory />
        <Clients />
      </div>
      <Aboutus />
      <GroupCompanies />
      <News />
      <Careers />
    </div>
  );
}

export default Home;
