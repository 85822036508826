import axios from "axios";

export const SendFormData = (data, url) => {
  return axios({
    method: "POST",
    url: url,
    data: data,
  }).then((response) => {
    return response.data;
  });
};
