import AnimatedNumber from "animated-number-react";
import { content } from "../shared/flat-db.js";

function Careers() {
  const formatValue = (value) => value.toFixed(0);
  return (
    <div className="row py-4 career-section bg-white">
      <div className="col-12">
        <div className="container">
          <div className="row">
            <div className="col-12 px-0">
              <h4 className="h4 tt-upperscase text-oceanblue">
                {content.home.data.careers.title}
              </h4>
              <div className="d-flex flex-wrap flex-lg-nowrap align-items-start mb-4">
                <h2 className="pr-5 font-weight-bold text-mendiblue">
                  {content.home.data.careers.heading}
                </h2>
                <div className="p-0 w-75 pb-3">
                  <div className="tabbed-nav">
                    <ul className="d-flex justify-content-between align-items-center pl-0">
                      {content.home.data.careers.numbers.map((item, index) => (
                        <li key={index}>
                          <span className="numbered text-oceanblue">
                            <AnimatedNumber
                              value={item.number}
                              formatValue={formatValue}
                            />
                          </span>
                          <br />
                          {item.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Careers;
