import srcLogoBlack from "../assets/logo-blk.svg";
import srcAboutShip from "../assets/about-ship.png";
import srcMendiDigitalLogo from "../assets/mendi-digital-logo-white.svg";
import srcMap from "../assets/map.png";

import srcFuelIcon from "../assets/fuel.svg";
import srcTruckIcon from "../assets/truck.svg";
import srcShipIcon from "../assets/ship.svg";
import srcPlaneIcon from "../assets/plane.svg";
import srcRailIcon from "../assets/rail.svg";

import srcFuelIconW from "../assets/fuel-w.svg";
import srcTruckIconW from "../assets/truck-w.svg";
import srcShipIconW from "../assets/ship-w.svg";
import srcPlaneIconW from "../assets/plane-w.svg";
import srcRailIconW from "../assets/rail-w.svg";

import srcCargoShipIcon from "../assets/cargo-ship.png";
import srcCargoTrainIcon from "../assets/cargo-train.png";
import srcExcarvatorIcon from "../assets/construction-excavator.svg";
import srcTruckIcon2 from "../assets/truck_2.svg";

import srcSSMendiShip from "../assets/ssmendi-01.svg";
import srcDropdownArrow from "../assets/downarrow.svg";
import srcArrowLeft from "../assets/cta-button.png";
import srcGoLogo from "../assets/go_logo_white.svg";

import srcClientTotal from "../assets/clients/client1.png";
import srcClientEskom from "../assets/clients/client2.png";
import srcClientPuma from "../assets/clients/client3.png";
import srcClientFraseralex from "../assets/clients/client4.png";
import srcClientConsol from "../assets/clients/client5.png";
import srcClientPrasa from "../assets/clients/client6.png";
import srcClientTransnet from "../assets/clients/client7.png";
import srcClientTrident from "../assets/clients/client8.png";
import srcClientTrasnetFrieght from "../assets/clients/client9.png";

export const logo = srcMendiDigitalLogo;
export const logoBlack = srcLogoBlack;
export const aboutShip = srcAboutShip;
export const ssMendiShip = srcSSMendiShip;
export const dropdownArrow = srcDropdownArrow;
export const mapDirections = srcMap;
export const goLogo = srcGoLogo;

export const clients = {
  consol: srcClientConsol,
  eskom: srcClientEskom,
  fraseralexander: srcClientFraseralex,
  prasa: srcClientPrasa,
  puma: srcClientPuma,
  total: srcClientTotal,
  transnet: srcClientTransnet,
  transnetfrieght: srcClientTrasnetFrieght,
  trident: srcClientTrident,
};

export const services = {
  fuel: srcFuelIcon,
  truck: srcTruckIcon,
  plane: srcPlaneIcon,
  rail: srcRailIcon,
  ship: srcShipIcon,
};

export const ourStory = {
  fuel: srcFuelIconW,
  truck: srcTruckIconW,
  plane: srcPlaneIconW,
  rail: srcRailIconW,
  ship: srcShipIconW,
  ptp: {
    ship: srcCargoShipIcon,
    train: srcCargoTrainIcon,
    excavator: srcExcarvatorIcon,
    truck: srcTruckIcon2,
    cta: srcArrowLeft,
  },
};
