import { services, dropdownArrow } from "../components/Images.js";
import { content } from "../shared/flat-db.js";

function Services() {
  return (
    <div className="container-fluid bg-white pt-4">
      <div className="row justify-content-start">
        <div className="col-11 p-0 posr-255">
          <h2 className="text-heading m-0 pl-5 text-uppercase text-green font-weight-bold">
            {content.services.title}
          </h2>
          <div className="bg-green p-4 pb-5 text-white ">
            <div className="d-flex justify-content-end">
              <div className="scroll-arrow">
                <img src={dropdownArrow} width="32px" alt="drop-arrow" />
              </div>
            </div>
            <div className="d-flex flex-wrap flex-lg-nowrap align-items-center justify-content-center">
              <div className="para-high lh-1 p-5">
                {content.services.data.banner.heading}
              </div>
              <p className="p-5">{content.services.data.banner.paragraph}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row pt-5">
          <div className="col-12 col-lg-6 pt-4">
            <h4 className="h4 tt-upperscase text-oceanblue pb-4">
              {content.services.data.main.heading}
            </h4>
            <h2 className="pr-5 font-weight-bold text-mendiblue">
              {content.services.data.main.subheading}
            </h2>
          </div>
          <div className="col-12 col-lg-6 text-oceanblue pt-5">
            <p className="pt-5">{content.services.data.main.paragraph}</p>
          </div>
        </div>

        <div className="row">
          <div className="col-12 d-flex flex-row flex-wrap justify-content-around py-5 text-mendiblue">
            <div className="icons text-center">
              <img src={services.ship} width="64px" alt="shipping-service" />
              <br />
              <h3 className="pt-3 text-oceanblue font-weight-bold">
                {content.services.data.main.items[0]}
              </h3>
            </div>

            <div className="icons text-center">
              <img src={services.rail} width="64px" alt="rail-service" />
              <br />
              <h3 className="pt-3 text-oceanblue font-weight-bold">
                {content.services.data.main.items[1]}
              </h3>
            </div>

            <div className="icons text-center">
              <img src={services.truck} width="64px" alt="logistics" />
              <br />
              <h3 className="pt-3 text-oceanblue font-weight-bold">
                {content.services.data.main.items[2]}
              </h3>
            </div>

            <div className="icons text-center">
              <img src={services.plane} width="64px" alt="plane-service" />
              <br />
              <h3 className="pt-3 text-oceanblue font-weight-bold">
                {content.services.data.main.items[3]}
              </h3>
            </div>

            <div className="icons text-center">
              <img src={services.fuel} width="64px" alt="fueling" />
              <br />
              <h3 className="pt-3 text-oceanblue font-weight-bold">
                {content.services.data.main.items[4]}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
